<template>
    <div class="row">
        <div class="col">
            <h5 class="h6 my-3">{{$t('motor.status-motoru')}}:</h5>

            <div class="row">

                <div class="col-md-4">
                    <div class="prograf">
                        <div class="h5 text-center">{{$t('motor.deska.hodnota-vibraci')}}</div>
                        <div class="kolo m-auto " >
                            <ejs-circulargauge background="transparent"  width='220'  height="220">
                                <e-axes>
                                    <e-axis  :majorTicks= 'majorTicks' :minorTicks= 'minorTicks'  :lineStyle= 'lineStyle'   startAngle="-180"   endAngle="90"  :labelStyle="{ position:'Inside', font: { color: '#1E7145', size: '0px' }}">
                                        <e-pointers>

                                            <e-pointer :value="(vibrace)" color= '#000000' pointerWidth=5 radius='90%'></e-pointer>
                                        </e-pointers>
                                    </e-axis>
                                </e-axes>
                            </ejs-circulargauge>
                        </div>
                        <router-link class="alarmlink"  :to="`/main/motors/${motor.token}/alarms`">
                            <div class="kolecko">
                                <img :src="vibrImageUrl(motor)" />
                            </div>
                            <span>{{ $t('motor.deska.alarmy-link') }}</span>
                        </router-link>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="prograf">
                        <div class="h5 text-center">{{$t('motor.deska.hodnota-teploty')}}</div>

                        <div class="kolo">
                            <ejs-circulargauge background="transparent" width='220' height="220">
                                <e-axes>
                                    <e-axis  :majorTicks= 'majorTicks' :minorTicks= 'minorTicks'  :lineStyle= 'lineStyle'   startAngle="-180"   endAngle="90"  :labelStyle="{ position:'Inside', font: { color: '#444', size: '0px' }}">
                                        <e-pointers>

                                            <e-pointer :value="teplota" color= '#000000' pointerWidth=5 radius='90%' ></e-pointer>
                                        </e-pointers>
                                    </e-axis>
                                </e-axes>
                            </ejs-circulargauge>
                        </div>
                        <router-link class="alarmlink"  :to="`/main/motors/${motor.token}/alarms`">
                            <div class="kolecko">
                                <img :src="tempImageUrl(motor)" >
                            </div>
                            <span>{{ $t('motor.deska.alarmy-link') }}</span>
                        </router-link>
                    </div>
                </div>


                <div class="col col-md-4"><div class="prograf px-3 pb-1" >
                    <div class="row" >
                        <div class="col-12">{{$t('m.PViewEngine.ServisníInterval')}}: <span v-if="daysS">{{Math.round(daysS)}}/{{motor.intervalS}}</span><span v-else> - </span></div>
                        <div class="col-12 mt-1 mb-3">
                            <div class="progress">
                                <div class="progress-bar bg-bee" role="progressbar" :style="'width: '+v_intervalS+'%'" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </div>
                    <div class="row" >
                        <div class="col-12">{{$t('m.PViewEngine.MěřícíInterval')}}: <span v-if="daysM!==null ">{{Math.round(daysM)}}/{{motor.intervalM}}</span><span v-else> - </span>   </div>
                        <div class="col-12">
                            <div class="progress mt-1 mb-3">
                                <div class="progress-bar bg-bee" role="progressbar" :style="'width: '+v_intervalM+'%'" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="motor.intervalL>0">
                        <div class="col-12">{{ $t('m.PViewEngine.MazacíInterval')}}: <span v-if="daysL">{{Math.round(hoursL)}}/{{motor.intervalL}}</span></div>
                        <div class="col-12">
                            <div class="progress mt-1 mb-3">
                                <div class="progress-bar bg-bee" role="progressbar" :style="'width: '+v_intervalL+'%'" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </div>


                    <div class="row mb-2">
                        <div class="col col-6">{{$t('m.PViewEngine.UvedeníDoProvozu') }}</div>
                        <div class="col col-6">{{datumAktivity(2) ? df(datumAktivity(2)) : '-'}}</div>
                    </div>

                    <div class="row mb-2">
                        <div class="col col-6">{{$t('m.PViewEngine.PosledníServis')}}</div>
                        <div class="col col-6">{{datumAktivity(3) ? df(datumAktivity(3)) : ''}}</div>
                    </div>

                    <div class="row mb-2">
                        <div class="col col-6">{{$t('m.PViewEngine.PosledníMěření')}}</div>
                        <div class="col col-6">{{datumAktivity(5) ? df(datumAktivity(5)) : ''}}</div>
                    </div>
                </div>
                </div>

            </div>


            <div class="row">
                <div class=" col-md-4 ">
                    <div class="prograf" style="min-height:240px;"><div class="h5 text-center">{{$t('motor.deska.vyvoj-vibraci')}}</div>
                        <router-link :to="`/main/motors/${motor.token}/chart#vib`" class="expand"></router-link>
                        <GChart
                                type="LineChart"
                                :data="chartDataVib"
                                :options="chartOptions" :key="chartKey"
                                v-if="chartDataVib.length>1"
                        />

                    </div>
                </div>
                <div class=" col-md-4 " >
                    <div class="prograf" style="min-height:240px;">
                        <div class="h5 text-center">{{$t('motor.deska.vyvoj-teploty')}}</div>
                        <router-link :to="`/main/motors/${motor.token}/chart#temp`" class="expand"></router-link>
                        <GChart
                                type="LineChart"
                                :data="chartDataT"
                                :options="chartOptions" :key="chartKey2"
                                v-if="chartDataT.length>1"
                        />

                    </div>
                </div>
                <div class=" col-md-4">
                    <div class="prograf">
                            <div class="h5 text-center">{{$t("m.PViewEngine.PlusAktivity")}}</div>
                            <router-link :to="`/main/motors/${motor.token}/chart`" class="expand"></router-link>
                            <div class="mikro-calendar" id="mikro-kalendar-kont">
                                <Calendar :motor="motor" :mikro="true"></Calendar>
                            </div>
                        </div>
                </div>



            </div>


        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { CircularGaugePlugin } from "@syncfusion/ej2-vue-circulargauge";
    Vue.use(CircularGaugePlugin);
    const moment = require('moment');
    require('moment/locale/cs');
    moment.locale('cs');

    import VueGoogleCharts from 'vue-google-charts'
    import Calendar from "./Calendar";
    import motorMixin from "@/views/motor/motorMixin";
    Vue.use(VueGoogleCharts)

    export default {
        name: "Deska",
        components: {Calendar},
        mixins:[motorMixin],
        data(){
            return {
                v_intervalS:'',
                 v_intervalL:'',
                 v_intervalM:'',
                chartDataT: [],
                chartDataVib: [],
                vibrace:0,
                teplota:0,
                daysL: 0,
                hoursL:0,
                daysM: null,
                daysS: null,
                motor:{activities:[]},
                lineStyle: {
                    width: 0,
                    color: 'transpatent'
                },
                majorTicks: {
                    interval: 10,
                    color:'red',
                    height: 10,
                    width: 0
                },
                minorTicks: {
                    interval: 5,
                    color:'green',
                    height: 5,
                    width: 0
                },
                chartKey2:2,
                chartKey:2,



                chartOptions: {
                    //height: 400,
                    //width: 400,
                    isStacked: true,
                    legend:{position:'none'},
                    hAxis: {
                        format: 'd.M.yy',
                        title: 'Date',
                        showTextEvery: 3,
                        textStyle: {fontSize: 11},
                        viewWindowMode:'explicit',
                        minValue: moment().subtract(1, 'months').toDate(),
                        viewWindow: {
                            min: moment().subtract(1, 'months').toDate(),
                            max: moment().add(1, 'months').toDate()
                        },
                    },
                    vAxis: {
                        showTextEvery: 2,
                        left: 0,
                        gridlines: {
                            count: 5
                        },
                        title: "",
                        viewWindow: {
                            min: 0
                            //     max: 200
                        },

                    },
                    curveType: 'function',
                    pointSize: 3

                }
            }
        },
        mounted(){

            this.motor = this.$parent.motor
            this.$parent.$on('motorUpdated', data => {
                this.motor = data //this.$parent.motor
                this.prepareData()
                this.vypocitajAlarmy()
                this.$emit('motorUpdated2', this.motor)
            })
            this.prepareData()
            if (this.motor.id) this.vypocitajAlarmy()
        },
        methods: {



            vypocitajAlarmy(){
                this.vibrace=null
                this.teplota=null
                if (this.motor.states){
                        this.vibrace = this.motor.states.vValue
                        this.teplota = this.motor.states.tValue

                }
                if (this.vibrace >0 && this.teplota>0) return ;


                let lastD=0;
                let lastM=null
                for( var i=0; i< this.motor.activities.length; i++){
                    let akt = this.motor.activities[i];
                    if (parseInt(akt.ActivityType) == 5){

                        let d = new Date(akt.Time);
                        if (!lastD || lastD.getTime() < d.getTime() ){
                            lastD = d
                            lastM = this.motor.activities[i]
                        }
                    }
                }
                let v
                let t
                if (lastM){
                    v = parseFloat(lastM.VibrationAxisA)
                    if (v< parseFloat(lastM.VibrationAxisB)){
                        v= parseFloat(lastM.VibrationAxisB)
                    }
                    if (v< parseFloat(lastM.VibrationAxisC)){
                        v= parseFloat(lastM.VibrationAxisC)
                    }
                    t = parseFloat(lastM.TemperatureT1)
                    if (t < parseFloat(lastM.TemperatureT2) ) t =parseFloat(lastM.TemperatureT2)
                    if (t < parseFloat(lastM.TemperatureT3) ) t =parseFloat(lastM.TemperatureT3)
                }
                var maxV = parseFloat( this.motor.alarms.v3max)
                var maxT = parseFloat( this.motor.alarms.t3max)

                if (!this.vibrace) this.vibrace = (v / maxV ) * 100
                if (!this.teplota) this.teplota = (t / maxT) * 100

            },
            datumAktivity(type){
                if (!this.motor.activities) return null
                type=parseInt(type);
                var res;
                var lastD=null;
                /*
                * if (parseInt(a.ActivityType) == 4) {
                        uzMazal = true;
                        if (!lastLdate || lastLdate.getTime() < d.getTime() ){
                            lastLdate = d
                        }
                    }
                * */
                for( var i=0; i< this.motor.activities.length; i++){
                    let akt = this.motor.activities[i];
                    if (parseInt(akt.ActivityType) == type){
                        res = this.motor.activities[i];
                        let d = new Date(res.Time);
                        if (!lastD || lastD.getTime() < d.getTime() ){
                            lastD = d
                        }
                        if (type<3) break;
                    }
                }
                //if (res && res.Time) return res.Time;

                if (type<1) return this.motor.created_at;
                return lastD;
            },

            computeDays(d){
                const d1 = new Date(d).getTime()
                const d2 = new Date().getTime();

                const diffTime = Math.abs(d2 - d1);
                //console.log('dify', d2/1000, d1/1000, diffTime)
                return (diffTime / (1000 * 60 * 60 * 24));
            },
            computeHours(d){
                const d1 = new Date(d)
                const d2 = new Date();

                const diffTime = Math.abs(d2 - d1);
                //console.log('dify', d2/1000, d1/1000, diffTime)
                return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            }



            /*
                intervalL = 90;
              intervalM = 30;
              IntervalS = 365;
            */
            , get_intervalS(){
                var d = this.datumAktivity(3);
                // určitě bude, protože v nastavení je 365 od uvedení do provozu. To znamená, že tam bude 20/365 pokud uběhlo 20 dnů od uvedení do provozu,
                // nebo od poslední údržby. Tuto hodnotu pak bude ukazovat i teploměr.
                if (!d) d = this.datumAktivity(2);

                if (!d) return 0;
                const interval = this.motor.intervalS
                const days = this.computeDays(d)
                this.daysS=days
                const x = 1 - (interval - days) / interval;
                return x*100;

            }
            , get_intervalM(){
                const d = this.datumAktivity(5);
                if (!d) return -1;
                const interval = this.motor.intervalM
                const days = this.computeDays(d)
                this.daysM=days
                const x = 1 - (interval - days) / interval;
                return x*100;
            }
            , get_intervalL(){
                var d = this.datumAktivity(4);
                this.daysL=0;
                if (!d) d = this.datumAktivity(2);

                if (!d) return 0;
                const interval = this.motor.intervalL
                const days = this.computeDays(d)
                this.daysL=days
                this.hoursL = days * this.motor.hours_daily
                const x = 1 - (interval - this.hoursL) / interval;
                return x*100;

            },

            vypocitatPruhy(){
                this.v_intervalL = this.get_intervalL()
                this.v_intervalS = this.get_intervalS()
                this.v_intervalM = this.get_intervalM()
            },




            df: function (time, format) {
              moment.locale(this.$i18n.locale);
                if (!time) return '';
                var a = moment(time);
                return a.format(format ? format : "LLL")
            },

            prepareData(){
                this.vypocitatPruhy()

                var vib = [['Date, time', 'Vibration axis A', 'Vibration axis B' ]];
                this.chartDataT=[['Date, time', 'Temperature T1', 'Temperature T2', 'Temperature T3']];
                var row;
                for(let i in this.motor.activities){
                    const a = this.motor.activities[i]
                    if (parseInt(a.ActivityType) == 5) {
                        let d = new Date(a.Time)
                        row = [d,
                            parseFloat(a.VibrationAxisA) > 0 ? parseFloat(a.VibrationAxisA) : 0,
                            parseFloat(a.VibrationAxisB) > 0 ? parseFloat(a.VibrationAxisB) : 0]
                        //    parseFloat(a.VibrationAxisC) >0 ? parseFloat(a.VibrationAxisC) :  0]
                        if (parseFloat(a.VibrationAxisA) > 0 ) vib.push(row)
                        if (parseFloat(a.TemperatureT1)>0) this.chartDataT.push([d,
                            parseFloat(a.TemperatureT1)>0 ? parseFloat(a.TemperatureT1) : 0,
                            parseFloat(a.TemperatureT2)>0 ? parseFloat(a.TemperatureT2) : 0,
                            parseFloat(a.TemperatureT3)>0 ? parseFloat(a.TemperatureT3) : 0])
                    }
                }
                this.chartDataVib = vib;
            },

        }
    }
</script>

<style scoped lang="scss">


    .kolo{
        background-color: white;
        background-image: url('/img/teplomer.png');
        background-size: 200px ;
        width:220px;
        margin:auto;
        background-repeat: no-repeat;
        background-position: 10px 15px;

    }


    .prograf{

        background:white;
        padding-top:10px;
        margin-bottom:20px;
        position:relative;
    }

    .expand{
        position: absolute;
        top:10px;
        right:10px;
        width:20px;
        height: 20px;
        background: url('/img/expand.svg');
        &:hover{
            background-color: #f0f0f0;
        }
    }

    .mikro-calendar{
            padding-left:32px;

        margin-right:10px;
        height: 200px;
        border-bottom: 10px solid white;
        overflow: hidden;
    }


    .alarmlink{
        display: block;
        position: absolute;

        right:50px;
        top:180px;
        width:40px;
        text-align:center;
        .kolecko{
            margin-left:2px;
            //border:2px solid #666666;
            border-radius:17px;
            width:34px;
            height:34px;
            text-align: center;
            img{
                width:33px;
                height:33px;
                margin-top:1px;
            }
        }
        span{
            color:#666;
        }
    }

</style>
